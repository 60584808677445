import { SmartHireNavBar } from '@seek/smart-hire-ui';
import {
  Box,
  Button,
  Divider,
  IconChevron,
  Inline,
  Loader,
  Stack,
  Text,
} from 'braid-design-system';
import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Menu } from 'src/components/molecules/Menu/Menu';
import { MenuButton } from 'src/components/molecules/Menu/MenuButton';
import { UserContext } from 'src/contexts/UserContex/UserContextProvider';
import { useCheckIsLoggedIn } from 'src/hooks/useCheckIsLoggedIn/useCheckIsLoggedIn';
import { useSmartHireClient } from 'src/hooks/useSmartHireClient/useSmartHireClient';
import { topNavLinks } from 'src/routes/routes';

import * as styles from './Nav.css';

export const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { smartHireClient } = useSmartHireClient();
  const {
    data: user,
    error: userError,
    isFetching: isFetchingUser,
  } = useContext(UserContext);
  const [currentTab, setCurrentTab] = useState('/');
  const isLoggedIn = useCheckIsLoggedIn();
  const companyRelations = useMemo(
    () => user?.companyRelations ?? [],
    [user?.companyRelations],
  );
  const currentCompany = useMemo(
    () =>
      user?.companyRelations?.find(
        (cr) => cr.companyId === Number(user?.companyId),
      ),
    [user?.companyId, user?.companyRelations],
  );

  useEffect(() => {
    setCurrentTab(location.pathname);
  }, [location.pathname]);

  const renderUsername = (open: boolean) => {
    if (userError) {
      return <Text>Failed to load user</Text>;
    }
    if (isFetchingUser) {
      return <Loader size="xsmall" />;
    }
    return (
      <Text>
        {`${user?.name} `}
        <IconChevron direction={open ? 'up' : 'down'} alignY="lowercase" />
      </Text>
    );
  };

  const renderCurrentCompany = (closeMenu: () => void) => {
    if (
      companyRelations &&
      companyRelations.length > 1 &&
      currentCompany?.company
    ) {
      return (
        <Box marginBottom="xsmall">
          <Stack space="small">
            <Text weight="strong" maxLines={3}>
              {currentCompany.company.name}
            </Text>
            <Text maxLines={1}>{currentCompany.company.seekCompanyId}</Text>
            <Button
              tone="formAccent"
              variant="ghost"
              onClick={() => {
                closeMenu();
                navigate('/account-selection');
              }}
            >
              Switch Account
            </Button>
            <Divider />
          </Stack>
        </Box>
      );
    }

    return <></>;
  };

  const renderAccountDetails = (closeMenu: () => void) => {
    if (user?.companyId) {
      return (
        <MenuButton
          onClick={() => {
            closeMenu();
            navigate('/account');
          }}
        >
          Account Details
        </MenuButton>
      );
    }

    return <></>;
  };

  return (
    <SmartHireNavBar
      variant={isLoggedIn ? 'dark' : 'light'}
      navItems={
        isLoggedIn
          ? Object.values(topNavLinks).map(({ label, path }) => ({
              label,
              isActive: currentTab.startsWith(path),
            }))
          : []
      }
      onNavItemClick={(navItem: string) => {
        const path = topNavLinks[navItem]?.path;
        if (path) {
          navigate(path);
        }
      }}
    >
      {isLoggedIn && (
        <Box paddingX="small" className={styles.navButton}>
          <Inline space="none">
            <Menu
              width="medium"
              trigger={(triggerProps, isOpen) => (
                <Box userSelect="none" cursor="pointer" {...triggerProps}>
                  {renderUsername(isOpen)}
                </Box>
              )}
              body={({ closeMenu }) => (
                <Box padding="small">
                  {renderCurrentCompany(closeMenu)}
                  <Stack space="none">
                    {renderAccountDetails(closeMenu)}
                    <MenuButton
                      tone="critical"
                      onClick={() => {
                        closeMenu();
                        smartHireClient.logout();
                      }}
                    >
                      Sign out
                    </MenuButton>
                  </Stack>
                </Box>
              )}
            />
          </Inline>
        </Box>
      )}
    </SmartHireNavBar>
  );
};
